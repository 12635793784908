
/* removing background in doctor card section page on the small screen */
@media (max-width: 640px) {    
    .doctor-image{
        background: transparent !important;
    }
}


/* swiper style in our top services component */
.our-top-services-swiper .swiper-wrapper{
    @apply lxl:content-between;
}

/* swiper style in how it works component */
.how-it-works-swiper .swiper-wrapper{
    @apply lxl:content-between;
}