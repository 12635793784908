
nav ul li{
    @apply max-sm:w-full max-sm:text-center max-sm:border-b max-sm:px-[116px] max-sm:py-3 max-sm:border-light-primary;
}

nav ul li:hover :not(.active){
    border-bottom: 1px solid #4E88FF;
}

nav ul li .active{
    color: #88A9FF;
    border-bottom: 1px solid #88A9FF;
}