
/* icon style */

summary{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

summary::after {
    content: '';
    background: url(../asserts/home/faq-summary-icon.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-left: 0.75em;
    transition: 0.2s;
    @apply w-[8.82px] h-3 xl:w-5 xl:h-5;
}


details[open] > summary::after{
    transform: rotate(180deg);
}



/* details section style */
details summary+p{
    padding: 20px;
}